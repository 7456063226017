import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import SongTrackLoader from '../../components/loader/songTrackLoader';
import MonthlyTopSongs from '../../components/monthlyTopSongs/monthlyTopSongs';
import RightSide from "../../components/rightSide/rightSide";
import SongTrack from '../../components/songTrack/songTrack';
import Pagination from "react-js-pagination";
import Spinner from 'react-bootstrap/Spinner';
import MusicDashboard from '../../layouts/musicDashboard';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { connect } from 'react-redux';

import * as songActions from '../../store/song/actions';
import * as genreActions from '../../store/genres/actions';


const  NewRelease = ({ loggingIn, genres, getGenresWithCount, getLatestSong, newRelease, paginationData, displayLoading, hideLoading }) => {

    const navigate = useNavigate();
    const scrollRef = useRef(null);
    const [ page, setPage ] = useState(1);
    const [ order, setOrder ] = useState('entity_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [ songSectionId, setSongSectionId ] = useState(1);
    const [ currentGenreId, setCurrentGenreId ] = useState();
    const [ processing, setProcessing ] = useState(false);
    const [ selectedGenres, setSelectedGenres ] = useState([]);
	
  	useEffect(() => { 
		if(loggingIn !== undefined && loggingIn === false){
			navigate('/sign-in');
		} else {
			loadData()
		}
	   },[])
	   
	const loadData = async() =>{
        // Get genres filter to display on homepage
        displayLoading();
        setProcessing(true);
		setCurrentGenreId('all')
        await getGenresWithCount();
        await getLatestSong({page: page, order: order, limit: 20, orderDir, song_section_id: songSectionId});
        hideLoading();
        setProcessing(false);
        window.scrollTo({top: 0});
    } 
    
    const handlePageChange = (pageNumber) => {
        displayLoading();
        setProcessing(true);
        setPage(pageNumber);
        if(currentGenreId === 'all'){
          getLatestSong({page: pageNumber, order: order, limit: 20, orderDir, song_section_id: songSectionId}).then((res) => {
            hideLoading();
            setProcessing(false);
            scrollRef.current?.scrollIntoView({behavior: 'smooth'});
          })
        } else {
          getLatestSong({page: pageNumber, order: order, limit: 20, orderDir, song_section_id: songSectionId, genre_id: selectedGenres}).then((res) => {
            hideLoading();
            setProcessing(false);
            scrollRef.current?.scrollIntoView({behavior: 'smooth'});
          })
        }
        
    }
  
    const genresSong = (genre_id) => {
        setCurrentGenreId(genre_id)
        const arr = selectedGenres; //example array

        if(!arr.includes(genre_id)){   
            arr.push(genre_id);               //adding to array because value doesnt exists
        }else{
            arr.splice(arr.indexOf(genre_id), 1);  //deleting
        }
        setSelectedGenres(arr);
        // console.log('arr', arr, selectedGenres);

        setProcessing(true);
        displayLoading();
        setCurrentGenreId(genre_id)
        getLatestSong({page:1, order: 'entity_id', limit: 20, orderDir: 'DESC', song_section_id: songSectionId, genre_id: selectedGenres}).then((res) => {
            setProcessing(false);
            hideLoading();
            window.scrollTo({top: 0});
        })
    }
    
    const getAllSong = () => {
        displayLoading();
        setProcessing(true);
        setCurrentGenreId('all');
        setSelectedGenres([]);
        getLatestSong({page: 1, order: 'entity_id', limit: 20, orderDir: 'DESC', song_section_id: songSectionId}).then((res) => {
            setProcessing(false);
            window.scrollTo({top: 0});
        })
        hideLoading();
    }

	return (
			<MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true}} />}>
                <div className="content-sec">
                    <MonthlyTopSongs />
                    <div className="play-list-sec">
                        <div className="top-heading">
                            <h2>New Releases</h2>
                        </div>
                        
                        <div className="inner-block">
                            <div className="top-list">
                                    {
                                        genres && genres.length ? 
                                        <ul>
                                            <li className={currentGenreId === 'all' ? 'active cp' : 'cp' } onClick={() => getAllSong('0')}>{ currentGenreId === 'all' && processing ? <span className="text-3"><Spinner animation="border" variant="dark" size="sm" /></span> : <span>All</span>}</li>
                                            {
                                                genres.map((genre, index)=>{
                                                    if(genre.total > 0){
                                                        return <li key={`homeGenresSong${genre.genre_id}`} className={selectedGenres.indexOf(genre.genre_id.toString()) >= 0  ? 'active cp' : 'cp'}>{ currentGenreId === genre.genre_id &&  processing ? <span className="text-3">  <Spinner animation="border" variant="dark" size="sm" /></span> : <span className="text-3" onClick={() => genresSong(genre.genre_id)} dangerouslySetInnerHTML={{__html: genre.name}}></span>}</li>
                                                    } else {
                                                        return null;
                                                    }
                                                })
                                            }
                                        </ul>
                                        : ""
                                    }
                            </div>
                            <div ref={scrollRef}>  {    processing ? <SongTrackLoader itemCount={20} /> : <SongTrack songs={newRelease}  />  }</div> 
                        </div>
                    </div>
                    <div className="pagingnation-sec">
                        {
                        paginationData ? 
                            <Pagination
                                activePage={paginationData.page}
                                itemsCountPerPage={paginationData.perPageLimit}
                                totalItemsCount={parseInt(paginationData.totalCount)}
                                // pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                        /> : ''
                        }
                    </div>
                </div>
        </MusicDashboard>
    );
}

function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        newRelease: (state.song.homeNewRelease && state.song.homeNewRelease.songs ) ? state.song.homeNewRelease.songs : [],
        paginationData:  (state.song.homeNewRelease && state.song.homeNewRelease.pagination) ? state.song.homeNewRelease.pagination : '',
        genres: state.genre.genresWithCount,
        top20Albums: state.song.top20Albums,
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
		getLatestSong: (post) => dispatch(songActions.getNewRelease(post)),
        getGenresWithCount: () => dispatch(genreActions.getGenresWithCount()),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(NewRelease);
