import React, { useState, useEffect } from "react";

import CrateDownloadButton from '../../elements/songElements/crateDownloadButton';
import PlayPauseButton from '../../elements/songElements/playPauseButton';
import { Link } from "react-router-dom";
import DropboxButton from '../../elements/songElements/dropboxButton';
import ZipSongButton from '../../elements/songElements/zipSongButton';

import { checkCustomer } from '../../components/utlity/checkCustomer';
import {connect} from 'react-redux';
import * as actions from '../../store/song/actions';

import * as crateActions from '../../store/crate/actions';

const  CrateSongTrack = ({ songs, getCrateList, addSongToCrate, getParentCustoemrCrates, currentGenreId, perPageLimit, page, activeBtn, customerProfile, setEnabledDownloadButton, setZipped, displayDates }) => {
    const [ canAccess, setCanAccess ] = useState(false);
    const [activeSongId, setActiveSongId] = useState(0);
    const [zippedSongs, setZippedSongs ] = useState([]);
    
	const removeSongFromCrate = async (songId,  versionId ) => {
        await addSongToCrate(songId, versionId);
        await getCrateList({page: page, pageLimit: perPageLimit, genre_id: currentGenreId}, () => {
                    
        });
        getParentCustoemrCrates();
    }

    

    useEffect(() => { 
        if(checkCustomer(customerProfile)){
            setCanAccess(true);
        }
    },[customerProfile])

    const addZipSong = (song) => {
        // if(zippedSongs.length > 2 && zippedSongs.length <= 10){
            let versionId = song.version.song_version_id;
            let item = [...zippedSongs, versionId];
            setZippedSongs(item); 
        // }
        setZipped(item)
    }

    const removeZipSong = (song) => {
        // Filter out the item at the specified index
        if(zippedSongs.length<=10){
            let versionId = song.version.song_version_id;
            const updatedItems = zippedSongs.filter(item => item !== versionId)
            setZippedSongs(updatedItems);
            setZipped(updatedItems)
        }
    }

    useEffect(() => { 
        if(zippedSongs.length > 2 && zippedSongs.length <= 10){
            setEnabledDownloadButton(true)
        } else {
            setEnabledDownloadButton(false)
        }    
    },[zippedSongs])
 
    
    return (
        <div className="play-list">
        {
            songs && songs.length > 0 ? 
                songs.map((song, index)=>{
                    return <div className={ activeSongId == song.entity_id ? "block" : "block"} key={`songcrate${index}${song.entity_id}`}>
                                <div className="play-track">
                                    <PlayPauseButton parentComponent="songVersion" song={song} version={song.version} />
                                    <div className="track-name">
                                        <span className="text-2" dangerouslySetInnerHTML={{__html: song.title}}></span>
                                        { 
                                            song.artist_search && song.artist_search[0].artist && song.artist_search[0].artist.length > 0 ? 
                                                song.artist_search[0].artist.map((as, asIndex) => {
                                                    return <div key={`as${asIndex}-${song.entity_id}`} ><Link to={`/mp3/artist/${as}`} dangerouslySetInnerHTML={{__html: as}} className="text-5"></Link> <span className="text-5">&nbsp;{ asIndex < (song.artist_search[0].artist.length -1) ? song.artist_search[0].split_string : ''}&nbsp;</span></div>
                                                })
                                            :
                                            <div><Link to={`/mp3/artist/${song.artist}`} dangerouslySetInnerHTML={{__html: song.artist}} className="text-5"></Link></div>
                                        }
                                        {/* <span className="text-5" dangerouslySetInnerHTML={{__html: song.artist}}></span> */}
                                    </div>
                                </div>
                                <div className="play-track-content">
                                    <div>
                                        <span className="text-4">{song.max_bpm}</span>
                                        <span className="text-4">{song.song_key}</span>
                                        { displayDates ? <span className="text-5">{song.year}</span> : '' }
                                        <span className="text-4">{song.version.version_label}</span>
                                    </div>
                                    
                                    <div className="divider">
                                        {/* <FavouriteButton song={song} /> */}
                                        
                                        <ZipSongButton zippedSongs={zippedSongs} addZipSong={addZipSong} song={song} index={index} removeZipSong={removeZipSong} />
                                        { activeBtn == 'download' ? <CrateDownloadButton songId={song.entity_id} version={song.version} songSectionId={song.song_section_id} getCustomerCrateSongs={getParentCustoemrCrates} isCkExclusive={song.isCkExclusive} /> : ''}
                                        { activeBtn == 'dropbox' ? <DropboxButton songId={song.entity_id} version={song.version} songSectionId={song.song_section_id} /> : ''}
                                        
                                        <span onClick={() => removeSongFromCrate(song.entity_id, song.version.song_version_id)} className="cp" title="Remove from Crate">
											<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
											</svg>
										</span>
                                    </div>
                                    
                                </div>
                                
                        </div>
                    })
            : ''
        }
        </div>
    );
  
}


function mapStateToProps(state) {
  return {
    activeBtn: state.activeDownloadBtn.activeDownloadBtn,
    customerProfile: state.customer.customerProfile
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    addSongToCrate: (songId, versionId) => dispatch(actions.addSongToCrate(songId, versionId)),
    getCrateList: (post) => dispatch(crateActions.getCrateList(post)),
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(CrateSongTrack);
