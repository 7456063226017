import * as types from './type';

const initialState = {
  editors: [],
  editorSongs: '',
};



export default function editorReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_SONG_EDITORS: {
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          editors: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_EDITORS_SONG: {
      // console.log('action.payload', action.payload)
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          editorSongs: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    

    default: {
      return {
        ...state
      };
    }
    
  }

}