import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {  useNavigate } from "react-router-dom";
import { checkCustomer } from '../../components/utlity/checkCustomer';
import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';
import {connect} from 'react-redux';
import * as downloadActions from '../../store/download/actions';
import * as songActions from '../../store/song/actions';

const PlayerDownloadButton = ({ player, playList,  isPlaying, userProfile, downloadSongVersion, getCustomerDownloads, themeType, getCustomerCrates }) => {
    const MySwal = withReactContent(Swal) 
    const navigate = useNavigate();
    const [ downloadInProcess, setDownloadInProcess ] = useState(false);
    const [ currentVersionId, setCurrentVersionId ] = useState();
    const [songSectionId, setSongSectionId ] = useState(1);
    const [ canAccess, setCanAccess ] = useState(false);
    const [ isPremium, setIsPremium ] = useState(false);  

    useEffect(() => { 
        if(player && player.current && player.current.audio && player.current.audio.current){
            const matchedSong = getPlaylistItem(player.current.audio.current.src);
            if(matchedSong){
                setCurrentVersionId(matchedSong.version)
            }
        }
    },[isPlaying])

    useEffect(() => { 
        if(checkCustomer(userProfile)){
            setCanAccess(true);
        }
        if(checkPremiumMember(userProfile)){
            setIsPremium(true);
        }
    },[userProfile])

    const getPlaylistItem = (songSrc) => {
        var hasMatch = false;
		for (var index = 0; index < playList.length; ++index) {
            var songs = playList[index];
            if(songs.src == songSrc){
                hasMatch = songs;
                break;
            }
        }
        
		return hasMatch;
    }
       
    const downloadSong = (versionId, songSectionId) => {
        const version = {song_version_id: versionId}
        downloadSongVersion({ version: version}).then(res => {
            setDownloadInProcess(false)
            if(res.payload.response && res.payload.response.status === 403){
              alert(res.payload.response.data.message);
            } else if(res.payload.data) {
                getCustomerDownloads();
                const versionData = res.payload.data;
                const downloadFilename = versionData.uploaded_mp3_name;
                const url = songSectionId == 1 ? `song/songsContainer/${versionData.version_code}/${versionData.file_name}` : `ck_kut/songsContainer/${versionData.version_code}/${versionData.file_name}`;
                var downloadurl = `https://stage.clubkillers.com/neverbeendownload.php?url=${url}&file=${downloadFilename}`;
                window.location.assign(downloadurl);
              // CAll parent function
            }
            const myTimeout = setTimeout(callDownloadFunction, 3000);
            getCustomerCrates();
        })
    }

    const callDownloadFunction = () => {
        getCustomerDownloads()
    }
    
    const downloadVersion = (songSectionId, version) => {
        setDownloadInProcess(true);

        if(canAccess){
            downloadSong(version, songSectionId)
        } else {
            setDownloadInProcess(false);
            navigate('/subscription/plans')
        }
        // if(userProfile && userProfile.group_id == 2){
        //     if(userProfile.payment_profile_status == 'paid'  && userProfile.profile_status == 'ACTIVE'){
        //         downloadSong(version, songSectionId)
        //     } else if(userProfile.payment_profile_status == 'unpaid'){
        //         setDownloadInProcess(false);
        //         navigate('/subscription/plans')
        //     } else if(userProfile.payment_profile_status == "pending"){
        //         MySwal.fire({
        //             title: "Payment under processing",
        //             text: "Your payment is under processing. You can add song to your crate.",
        //             type: "info",
        //             allowOutsideClick: false,
        //         });
        //         setDownloadInProcess(false);
        //     } else if(userProfile.payment_profile_status == 'canceled' && userProfile.profile_status == 'Cancelled'){
        //         var d1 = new Date();
        //         var d2 = new Date(userProfile.cancel_download_date);
        //         var same = d1.getTime() === d2.getTime();
        //         // console.log('checked', userProfile.cancel_download_date)
        //         if(d2.getTime() > d1.getTime()){
        //             downloadSong(version, songSectionId)
        //         } else {
        //             setDownloadInProcess(false)
        //             navigate('/subscription/plans');
        //         }
        //     } else {
        //         setDownloadInProcess(false)
        //         navigate('/subscription/plans');
        //     }
        // } else if(userProfile && userProfile.group_id == 3){
        //     var d1 = new Date();
        //     var d2 = new Date(userProfile.staff_membership_expire_date);
        //     if(d2.getTime() > d1.getTime()){
        //         downloadSong(version, songSectionId)
        //     } else {
        //         setDownloadInProcess(false)
        //         navigate('/subscription/plans');
        //     }
        // }
    }
    
    if(downloadInProcess){
        return <Spinner animation="border" variant="danger" size="sm" />
    } else {
        return (
            <span onClick={() => downloadVersion(songSectionId, currentVersionId)} className="cp">
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.9994 5.42871H12.1423C12.2939 5.42871 12.4392 5.48891 12.5464 5.59608C12.6535 5.70324 12.7137 5.84859 12.7137 6.00014V14.5716C12.7137 14.7231 12.6535 14.8685 12.5464 14.9756C12.4392 15.0828 12.2939 15.143 12.1423 15.143H1.85658C1.70503 15.143 1.55969 15.0828 1.45252 14.9756C1.34536 14.8685 1.28516 14.7231 1.28516 14.5716V6.00014C1.28516 5.84859 1.34536 5.70324 1.45252 5.59608C1.55969 5.48891 1.70503 5.42871 1.85658 5.42871H2.99944" stroke={themeType == 'dark-theme' ? "#FFF": '#828282'} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.99902 0.857422V10.0003" stroke={themeType == 'dark-theme' ? "#FFF": '#828282'} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4.71387 7.71484L6.99958 10.0006L9.2853 7.71484" stroke={themeType == 'dark-theme' ? "#FFF": '#828282'} strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>
        )
    }
   
    
   

}
function mapStateToProps(state) {
    return {
        player: state.player.audioInstance,
        playList: state.playlist.playList,
        isPlaying: state.isplaying.isPlaying,
        userProfile: state.customer.customerProfile,
        themeType: state.theme.theme,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        downloadSongVersion: (version) => dispatch(downloadActions.downloadSongVersion(version)),
        getCustomerDownloads: () => dispatch(downloadActions.getCustomerDownloads()),
        getCustomerCrates: () => dispatch(songActions.getCustomerCrates()),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PlayerDownloadButton);
