import React, { useState, useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link }  from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner';
import ImageSliderLoader from '../../components/loader/imageSliderLoader';
import {connect} from 'react-redux';
import * as actions from '../../store/editors/actions';


const  ExclusiveEditors = ({ editors, getSongEditors }) => {

	useEffect(() => { 
		getSongEditors();
	},[])
    // console.log('editors', editors.length)
	const settings = {
		className: "slider variable-width",
		dots: false,
		infinite: false,
		centerMode: false,
		slidesToShow: 4,
		slidesToScroll: 4,
		variableWidth: true,
		arrows: true,
		responsive: [
		  {
			breakpoint: 1700,
			settings: {
			  slidesToShow: 3,
			  slidesToScroll: 3,
			}
		  },
		  {
			breakpoint: 1600,
			settings: {
			  slidesToShow: 4,
			  slidesToScroll: 4,
			}
		  },
		  {
			breakpoint: 1000,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			}
		  },
		]
	};

    return (
      <div className="slider-sec  top-twenty merchandise">
			<div className="top-heading">
				<h2>EXCLUSIVE EDITORS</h2>
				{/* <a href="//gear.clubkillers.com/collections/products" target="_blank" className="text-2">View all</a> */}
			</div>
			{
				editors && editors.length > 0 ?
					<Slider {...settings}>
						{
							editors.map((item, index) => {
                                return <div key={`exclusiveEditors${index}`} className="item">
                                    <div className="box text-center">
                                        <Link to={`/editor/${item.name}`}><img src={item.profile_pic} alt={item.name} title={item.name} /></Link>
                                        <Link to={`/editor/${item.name}`}>	<p className="text-4" style={{textAlign:'center'}}>{item.name}</p> </Link>
                                    </div>
                                </div>
							})
						}
					</Slider>
				: <ImageSliderLoader itemCount={8} />
			}
			
		</div>
				
				
    );
  
}


function mapStateToProps(state) {
	return {
		editors: state.editor.editors
	};
}
 
function mapDispatchToProps(dispatch) {
	return {
		getSongEditors: () => dispatch(actions.getSongEditors())
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ExclusiveEditors);
