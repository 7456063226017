import * as types from './type';

import { Editor }  from  '../../services/editor';

export function getSongEditors(){
  return {
    type: types.GET_SONG_EDITORS,
    payload: Editor.getSongEditors(),
  };
}

export function getEditorSongs(data){
  return {
    type: types.GET_EDITORS_SONG,
    payload: Editor.getEditorSongs(data),
  };
}