import React, { useState, useEffect } from "react";
import Search from "../search/search";
import ProfileImage from '../profileImage/profileImage';
import ActiveDownloadBtn from '../activeDownloadBtn/activeDownloadBtn';
import DropboxItemsModal from '../../elements/mp3Modal/dropboxItemsModal';
// import RequireFields from '../../components/popup/requireField';
// import FavouriteGenres from '../../components/popup/favouriteGenres';
import ReportLyrics from './reportLyrics';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { useNavigate, Link } from "react-router-dom";
import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';
import { checkCustomer } from '../../components/utlity/checkCustomer';
import {connect} from 'react-redux';
import * as dropboxAction from '../../store/dropbox/actions';
import * as commonAction from '../../store/common/actions';
import * as downloadActions from '../../store/download/actions';
import * as actions from '../../store/customer/actions';
import * as songActions from '../../store/song/actions';
// import {serialNumber} from 'serial-number';
import {isDesktop, isMobile, isTablet, browserName, browserVersion, osName, osVersion, isMobileOnly} from 'react-device-detect';

import {axiosReqOther} from "../../services/config";

const  MiddleHeader = ({ sendVerifyDeviceCode, verifyCode, userProfile, updateProfileData, setLeftMenuDisplay, isLeftmenuExpand, getCustomerDownloads, customerData, devices, removeUserDevice, user, getCustomerProfile, getUsersDevices, getCustomerPlayLists, loggingIn, getListenSongs }) => {
  const MySwal = withReactContent(Swal)
  const navigate = useNavigate();
  const [ showSelectWindow, setShowSelectWindow ] = useState(false);
  const [ leftExpand, setLeftExpand ] =  useState(isLeftmenuExpand);
  const [ userDeviceInfo, setUserDeviceInfo ] =  useState({
      device_type: isDesktop ? 'desktop' : isMobile ? 'mobile' : 'tablet',
      browser_name: browserName,
      browser_version: browserVersion,
      os_name: osName,
      os_version: osVersion,
      ip_address: '',
      token: localStorage.user,
      device_id: localStorage.getItem('deviceId')
  });
  const [ customerDeviceId, setCustomerDeviceId ] = useState();
  const [ isPremium, setIsPremium ] = useState(false);
  const [ processing, setProcessing ] = useState(false);
  const [ isOpenVerify, setIsOpenVerify ] = useState(false);
  const [ canAccess, setCanAccess ] = useState(false);

  useEffect(() => { 
    if(checkCustomer(userProfile)){
      setCanAccess(true);
    }
    // console.log(checkPremiumMember(userProfile))
    if(checkPremiumMember(userProfile)){
      setIsPremium(true);
    }
    getListenSongs();
  },[userProfile])

  useEffect(() => { 
    if(loggingIn !== undefined && loggingIn === false){
            window.location.href = '/sign-in';
    } 
  },[])

  const getIP =  () => {
    // console.log('ip')
    // const res = await axios.get("https://api.ipify.org/?format=json");
    // let deviceInfo = userDeviceInfo;
    // deviceInfo.ip_address = res.data.ip;
    // setUserDeviceInfo(deviceInfo);
  }

  useEffect(() => { 
    getDeviceId();
    getIP();
    getCustomerPlayLists({});
  },[userProfile]);

  
  useEffect(() => { 
    if(userProfile){
      getDeviceId();
      let isNewDevice = true;
      if(devices && devices.length > 0 && localStorage.getItem('deviceId')){
        for(let d=0; d<devices.length; d++){
          if(devices[d].device_id == localStorage.getItem('deviceId') && parseInt(devices[d].status) == 1 ){ 
            isNewDevice = false
            break;
          }
        }
        
        if(userProfile && userProfile.user_song == "1" && userProfile.group_id == "3"){
          // Don't open verify window
        } else {
          if(isNewDevice){
            // openVerifyWindow();
          }
        }
      }
    }
    
  },[devices]);

  const openVerifyWindow = () => { 
    setIsOpenVerify(true);
    MySwal.fire({
      title: "Did you log in from a new device?",
      text: "It looks like you’re logging in from a browser or device that’s not verified in your account. If you would like to use this device, please verify it.",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Verify Device',
      denyButtonText: `Cancel`,
      allowOutsideClick: false,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // send verification code 
        let deviceInfo = userDeviceInfo;
        deviceInfo.device_id = localStorage.getItem('deviceId');
        sendVerifyDeviceCode(userDeviceInfo);

        Swal.fire({
          title: 'Verify This Device',
          // input: 'text',
          html: '<p>We sent an activation code to <strong>'+userProfile.email+'</strong>, Please enter it below to verify this device.</p>'+
          '<p>Don\'t have access to that email? <a href="mailto: info@clubkillers.com" class="text-3" style="font-weight: 900; text-decoration: underline;">Contact Support</a></p>'+
          '<input id="swal-input1" class="swal2-input" style="display: flex; width: 100%;"  placeholder="Activation Code">' +
        '<input id="swal-input2" class="swal2-input" style="display: flex;  width: 100%;"  placeholder="Device Name" spellcheck="false">',
          // inputAttributes: {
          //   autocapitalize: 'off'
          // },
          confirmButtonText: 'Submit',
          allowOutsideClick: false,
          preConfirm: (code) => {

            return new Promise(function (resolve) {
              // Validate input
              if (document.getElementById('swal-input1').value == '') {
                  Swal.showValidationMessage("Enter Activation Code"); // Show error when validation fails.
                  Swal.enableButtons(); // Enable the confirm button again.
                  
              } else {
                  let deviceName = document.getElementById('swal-input2').value;
                  if(deviceName.length > 30){
                    Swal.showValidationMessage(
                      `Device Name should less then 30 characters`
                    );
                    Swal.enableButtons();
                  } else { 
                    Swal.resetValidationMessage(); // Reset the validation message.
                    const data = {
                      token: localStorage.user,
                      user: userProfile.entity_id,
                      verify_code: document.getElementById('swal-input1').value,
                      device_name: document.getElementById('swal-input2').value
                    }
                    // // const response = verifyDeviceCode(data);
                    const url = `customer/verify-device.json?store_id=1&lang_code=en`;
                    return axiosReqOther(url, data, 'post').then(res => { 
                      // window.location.reload();                  
                      updateProfileData(res.data);
                      getUsersDevices({token: user})
                      resolve([
                          document.getElementById('swal-input1').value,
                          document.getElementById('swal-input2').value
                      ]);
                      setIsOpenVerify(false)
                        return res;  
                    }).catch(err => { 
                        // console.log(err.response.data.message.invalid);
                          Swal.showValidationMessage(
                            `Request failed: ${err.response.data.message.invalid}`
                          );
                          Swal.enableButtons(); // Enable the confirm button again.
                    });
                  }
              }
          })            
          }
        })
      } else {
        // /swindow.location.href = '/logout';
      }
    });
  }
  
  // Generate or retrieve a unique identifier
  const getDeviceId = () => {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      deviceId = generateUniqueId();
      localStorage.setItem('deviceId', deviceId);
    }
    return deviceId;
  }

  // Generate a unique identifier
  const generateUniqueId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

 
  const verifyDeviceCode = (data) =>{
    return verifyCode(data).then(res => {
      // return res.json();
        // Swal.showValidationMessage(
      //   `Request failed: lklkl`
      //   )
    }).catch(err => { 
      return err; 
    });
  }

  const removeDevice = async(deviceId) => {
      setCustomerDeviceId(deviceId);
      setProcessing(true);
      const data = {
          token: user,
          customer_device_info_id: deviceId
      }
      await removeUserDevice(data);
      await getCustomerProfile(userDeviceInfo); 
      setProcessing(true);
  }

  useEffect(() => { 
    getCustomerDownloads();
    // getCustomerProfile(userDeviceInfo);
  },[]);
  
    const displayDropboxDownloads = () => {
      if(canAccess){
        setShowSelectWindow(!showSelectWindow);
      } else {
        navigate('/subscription/plans');
      }
      // if(userProfile && userProfile.group_id == 2){ 
      //   if(userProfile.payment_profile_status == 'paid'){
      //     setShowSelectWindow(!showSelectWindow);
      //   } else if(userProfile.payment_profile_status == 'unpaid'){
      //       navigate('/subscription/plans')
      //   } else if(userProfile.payment_profile_status == "pending"){
      //         MySwal.fire({
      //             title: "Payment under processing",
      //             text: "Your payment is under processing. You can add song to your crate.",
      //             type: "info",
      //             allowOutsideClick: false,
      //         });
      //   } else if(userProfile.payment_profile_status == 'canceled' && userProfile.profile_status == 'Cancelled'){
      //         var d1 = new Date();
      //         var d2 = new Date(userProfile.cancel_download_date);
      //         var same = d1.getTime() === d2.getTime();
      //         // console.log('checked', userProfile.cancel_download_date)
      //         if(d2.getTime() > d1.getTime()){
      //           setShowSelectWindow(!showSelectWindow);
      //         } else {
      //             navigate('/subscription/plans');
      //         }
      //     } else {
      //         navigate('/subscription/plans');
      //     }
      // } else if(userProfile && userProfile.group_id == '3'){ 
      //   setShowSelectWindow(!showSelectWindow);
      // }
    }


    const toggleLeftmenu = () => {              
        setLeftMenuDisplay(!leftExpand);
        setLeftExpand(!leftExpand);
    }


    return (
      <div className="top-header">
        {/* <RequireFields /> */}
        {/* <FavouriteGenres /> */}
        <div className="toggle-nav"  onClick={() => toggleLeftmenu()}>
          <svg className="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clipPath="url(#clip0_669_215863)">
							<path d="M15.445 7.28996L16.855 8.70996L13.565 12L16.855 15.29L15.445 16.71L10.735 12L15.445 7.28996Z" fill="#ff07b3"></path>
							<path d="M7.14502 17L9.14502 17L9.14502 7L7.14502 7L7.14502 17Z" fill="#ff07b3"></path>
						</g>
					</svg>
        </div>
        {<div className="burger-menu">
					<span className="cp" onClick={() => toggleLeftmenu()}>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M5 7.99902H19.0004M5 11.999H19.0004M5 15.999H19.0004" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path>
						</svg>
					</span>
				</div>}
        {/* <script type="text/javascript" src="https://www.dropbox.com/static/api/2/dropins.js" id="dropboxjs" data-app-key="9xu23uv21md3xav"></script> */}
        {/* { 
          customerData.customerProfile.group_id == '3' && customerData.customerProfile.user_song && customerData.customerProfile.user_song == '1' ? '' : 
                    parseInt(customerData.customerProfile.device_count) > 2 && isOpenVerify == false  ? 
                        <div className="CustomOverlay">
                            <div className="CustomPopup">
                                <div className="CustomPopupTop">
                                    <span>Max Device Limit Reached</span>
                                    <Link to={customerData.customerProfile.device_count > 2 ? '/logout' : '/'}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.5 16.5 12 12 7.5 7.5M16.5 16.5 12 12l4.5-4.5" stroke="#919191" strokeWidth="2" strokeMiterlimit="10"></path>
                                        </svg>
                                    </Link>
                                </div>
                                <h4>Please Remove Device</h4>
                                <p>
                                    You can have two devices saved to your account at a time. Please remove other devices below to start browsing.
                                </p>
                                {
                                    devices && devices.length > 0  ? 
                                        devices.map((device, index)=>{
                                            return <div className="RemoveBlock" key={`device-pop-${index}`}>
                                                        <h6>{device.device_name}</h6>
                                                        <span className="cp text-primary" onClick={() => removeDevice(device.customer_device_info_id)}>Remove</span>
                                                    </div>
                                        })
                                    : ''
                                }
                            </div>
                        </div>
                    : ''
                } */}
        <div>
      { isPremium ? <ActiveDownloadBtn displayDropboxDownloads={displayDropboxDownloads}  /> : ''}
      <ReportLyrics />
			<Search />
      <Link className="report-button" to={'/search/result'}>Advanced Search</Link>
			<ProfileImage />
        </div>
        { showSelectWindow  ? <DropboxItemsModal showState={showSelectWindow} ShowFunction={() => setShowSelectWindow(!showSelectWindow)}  /> : '' }
			</div>
    );
  
}


function mapStateToProps(state) {
    return {
      dropboxSongs: state.dropbox.dropboxSongs,
      userProfile: state.customer.customerProfile,
      isLeftmenuExpand: state.common.isLeftmenuExpand,
      customerData: state.customer,
      devices: state.customer.devices,
      user: state.authentication.user,
      loggingIn: state.authentication.loggedIn,
    };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getDropboxSongs: () => dispatch(dropboxAction.getDropboxSongs()),
    updateProfileData: (data) => dispatch(actions.updateProfileData(data)),
    setLeftMenuDisplay: (data) => dispatch(commonAction.setLeftMenuDisplay(data)),
    getCustomerDownloads: () => dispatch(downloadActions.getCustomerDownloads()),
    sendVerifyDeviceCode: (data) => dispatch(actions.sendVerifyDeviceCode(data)),
    verifyCode: (data) => dispatch(actions.verifyCode(data)),
    removeUserDevice: (data) => dispatch(actions.removeUserDevice(data)),
    getCustomerProfile: (data) => dispatch(actions.getCustomerProfile(data)),
    getUsersDevices: (token) => dispatch(actions.getUsersDevices(token)),
    getCustomerPlayLists: (data) => dispatch(actions.getCustomerPlayLists(data)),
    getListenSongs: () => dispatch(songActions.getListenSongs())
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(MiddleHeader);
