import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import Carousel from "../../components/carousel/carousel";
import RightSide from "../../components/rightSide/rightSide";
import SongTrack from '../../components/songTrack/songTrack';
import SongTrackLoader from '../../components/loader/songTrackLoader';

import MusicDashboard from '../../layouts/musicDashboard';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import * as actions from '../../store/trending/actions';

const TrendingTrackCurrent = ({getTrendingSongs, getTrendingTracksAlbums, albums, currentTrending, displayLoading, hideLoading}) => {
    
    const [ processing, setProcessing] = useState(false);
    const navigate = useNavigate();
    
        useEffect(() => { 
            if(!currentTrending){
                setProcessing(true);
                displayLoading();
                getTrendingSongs();
                
                hideLoading();
                setProcessing(false);
                window.scrollTo({top: 0});
            }
            if(!albums){
                getTrendingTracksAlbums({page: 1, pageLimit: 42, pagination: true});
            }
        },[])
    
    return (
        <MusicDashboard rightContent={<RightSide blocks={{ mostDownloaded: true, RecentPlayedSong: true}} />}>
			    <div className="content-sec">
                <Carousel items={albums.tracks ? albums.tracks : ''} itemLinkPrefix={'/trending-tracks/songs/'} primaryKeyName={'trending_track_id'} title={'Trending Tracks'} hideSeeAll={true} titleKey={'created_at'} imageKey={'cover_image'} showItemCount={5} />
                    <div className="top-heading">
                        <div className="back-arrow-head">
                            <span onClick={() => {
                                navigate(-1)
                            }} className="cp">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                            <h2>Trending Tracks</h2>
                        </div>
                        
                    </div>
                    
                    <div className="play-list-sec">
                        <div className="inner-block">
                        { processing ? <SongTrackLoader itemCount={20} /> : currentTrending && currentTrending.length > 0 ? <SongTrack songs={currentTrending} /> : ''}
                        </div>
                    </div>
                </div>
            </MusicDashboard>
        );
}

function mapStateToProps(state) {
   
    return {
        currentTrending: state.trending.currentTrending,
        albums: state.trending.albums
    };
}
    
function mapDispatchToProps(dispatch) {
    return {
        getTrendingSongs: () => dispatch(actions.getLatestTrendingSong()),
        getTrendingTracksAlbums: (data) => dispatch(actions.getLatestTrendingTrackAlbums(data)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendingTrackCurrent);