import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import {  useNavigate } from "react-router-dom";
import { checkCustomer } from '../../components/utlity/checkCustomer';
import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';
import { openPremiumMemberSwal } from '../../components/utlity/openPremiumMemberSwal';
import {connect} from 'react-redux';
import * as downloadActions from '../../store/download/actions';
import * as songActions from '../../store/song/actions';

const CrateDownloadButton = ({ version, songSectionId, userProfile, downloadSongVersion, getCustomerDownloads, getCustomerCrateSongs, isCkExclusive }) => {

    const navigate = useNavigate();
    const [ downloadInProcess, setDownloadInProcess ] = useState(false);
    const [ canAccess, setCanAccess ] = useState(false);
    const [ isPremium, setIsPremium ] = useState(false);  

    useEffect(() => { 
        if(checkCustomer(userProfile)){
            setCanAccess(true);
        }
        if(checkPremiumMember(userProfile)){
            setIsPremium(true);
        }
    },[userProfile])

    const downloadSong = (version, songSectionId) => {
        
        downloadSongVersion({ version: version}).then(res => {
            setDownloadInProcess(false)
            if(res.payload.response && res.payload.response.status === 403){
                navigate('/subscription/plans');
            } else if(res.payload.data) {
                getCustomerDownloads();
                getCustomerCrateSongs();
                const versionData = res.payload.data;
                const downloadFilename = versionData.uploaded_mp3_name;
                const url = songSectionId == 1 ? `song/songsContainer/${versionData.version_code}/${versionData.file_name}` : `ck_kut/songsContainer/${versionData.version_code}/${versionData.file_name}`;
                var downloadurl = `https://stage.clubkillers.com/neverbeendownload.php?url=${url}&file=${encodeURIComponent(downloadFilename)}`;
                window.location.assign(downloadurl);
              
            }
        })
    }
    
    const downloadVersion = (songSectionId, version) => {
        // if(isCkExclusive){
        //     if(isPremium){
        //         downloadSong(version, songSectionId)
        //     } else {
        //         setDownloadInProcess(false);
        //         openPremiumMemberSwal();
        //     }
        // } else {
            setDownloadInProcess(true);
            if(canAccess){
                    downloadSong(version, songSectionId)
            } else {
                setDownloadInProcess(false);
                navigate('/subscription/plans')
            }
        // }
    }
    
    if(downloadInProcess){
        return <Spinner animation="border" variant="danger" size="sm" />
    } else { 
        return (
            <span onClick={() => downloadVersion(songSectionId, version)} title="Download" className="cp">
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.9994 5.42871H12.1423C12.2939 5.42871 12.4392 5.48891 12.5464 5.59608C12.6535 5.70324 12.7137 5.84859 12.7137 6.00014V14.5716C12.7137 14.7231 12.6535 14.8685 12.5464 14.9756C12.4392 15.0828 12.2939 15.143 12.1423 15.143H1.85658C1.70503 15.143 1.55969 15.0828 1.45252 14.9756C1.34536 14.8685 1.28516 14.7231 1.28516 14.5716V6.00014C1.28516 5.84859 1.34536 5.70324 1.45252 5.59608C1.55969 5.48891 1.70503 5.42871 1.85658 5.42871H2.99944" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.99902 0.857422V10.0003" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4.71387 7.71484L6.99958 10.0006L9.2853 7.71484" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>
        )
    }
}
function mapStateToProps(state) {
    return {
        userProfile: state.customer.customerProfile
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        downloadSongVersion: (version) => dispatch(downloadActions.downloadSongVersion(version)),
        getCustomerDownloads: () => dispatch(downloadActions.getCustomerDownloads()),
        getCustomerCrates: () => dispatch(songActions.getCustomerCrates()),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CrateDownloadButton);
  