import React, { useEffect, useState, useRef} from "react";

//custom components
import SongTrackLoader from '../../components/loader/songTrackLoader';
import { Link } from "react-router-dom";
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import ExclusiveEditors from '../../components/editors/editors'
import RightSide from "../../components/rightSide/rightSide";
import Pagination from "react-js-pagination";
import MusicDashboard from '../../layouts/musicDashboard';
import SongTrack from '../../components/songTrack/songTrack';
import {connect} from 'react-redux';
import * as actions from '../../store/editors/actions';
import { useNavigate, useParams } from "react-router-dom";
import { hide } from "@popperjs/core";

const  EditorSongs = ({ getEditorSongs, editorSongs, displayLoading, hideLoading}) => {

    let match = useParams("/editor/:editor");
    const navigate = useNavigate();
    const scrollRef = useRef(null)
    const [editor, setEditor] = useState();
    const [ page, setPage ] = useState(1);
    const [ order, setOrder ] = useState('entity_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [ songSectionId, setSongSectionId ] = useState(1);
    const [ processing, setProcessing] = useState(false);
    const [ currentGenre, setCurrentGenre ] = useState();

    useEffect(() => { 
        setProcessing(true);
        displayLoading();
        setEditor(match.editor)
        getEditorSongs({page: 1, order: order, orderDir: orderDir, song_sectoin_id: songSectionId, editor: match.editor}).then((res) => {
            
        });
        setProcessing(false);
        hideLoading();
            window.scrollTo({top: 0});
    },[navigate]);

    // console.log('editorSongs', editorSongs)    

    const handlePageChange = async (pageNumber) => 
    {
        setProcessing(true);
        displayLoading();
        setPage(pageNumber);
        await getEditorSongs({page: pageNumber, order: order, orderDir: orderDir, song_section_id: songSectionId, editor: editor});
        hideLoading();
        setProcessing(false);
        scrollRef.current?.scrollIntoView({behavior: 'smooth'});
    }
    
    // console.log(genresWithCount.indexOf(match.id));
  return (
    <MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true}} />}>
        <div className="content-sec">
            <ExclusiveEditors />
            <div className="play-list-sec" ref={scrollRef}>
                <div className="top-heading">
                { editorSongs && editorSongs.editor ? 
                    <div className="back-arrow-head">
                            <span className="cp" onClick={() => {
                                setPage(0);
                                navigate(-1)
                            }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                            </span>
                            { editorSongs && editorSongs.editor ? <h2 > <span dangerouslySetInnerHTML={{__html: editorSongs.editor.name}} style={{width: 'auto', margin: '0', display: 'inline'}} /> EDITS</h2> : ''}
                        </div>
                    : ''
                    // <h2>{ editorSongs && editorSongs.editor ? <span dangerouslySetInnerHTML={{__html: editorSongs.editor.name}}></span> : ''}</h2>
                }
                </div>
                { 
                    editorSongs && editorSongs.editor ?
                    <div style={{backgroundImage: `url(${editorSongs.editor.profile_pic})`}} class="cu-block-inner">
                        <div className="cd-container cu-block-bg">
                            <div className="cu-midil-content">
                                <div className="cd-image">
                                    <img src={editorSongs.editor.profile_pic} alt={editorSongs.editor.name} />
                                </div>
                                <div className="cd-detail">
                                    <h3 dangerouslySetInnerHTML={{__html: editorSongs.editor.name}}></h3>
                                    {/* <h5><span>Curated By: </span><a href={curatedSongs.url} target="_blank" className="text-2">{curatedSongs.created_by}</a></h5> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
                }
                <div className="inner-block">
                        <div className="top-list">
                        
                                {/* {
                                    currentGenre && currentGenre.subgenre && currentGenre.subgenre.length ? 
                                    <ul>
                                        {
                                            currentGenre.subgenre.map((genre, index)=>{
                                                return <li key={`homeGenresSong${genre.genre_id}`} ><Link to={`/mp3/genres/${genre.genre_id}`} dangerouslySetInnerHTML={{__html: genre.name}}></Link></li>
                                            })
                                        }
                                    </ul>
                                    : ""
                                } */}
                        </div>
                        <div >
                        {
                            processing ? <SongTrackLoader itemCount={20} /> : 
                                editorSongs && editorSongs.songs && editorSongs.songs.length > 0 ?
                                    <SongTrack songs={editorSongs.songs}   /> 
                                : ''
                        }
                        </div>
                </div>
            </div>
            <div className="pagingnation-sec">
            {
                editorSongs && editorSongs.pagination ? 
                    <Pagination
                        activePage={editorSongs.pagination.page}
                        itemsCountPerPage={editorSongs.pagination.perPageLimit}
                        totalItemsCount={parseInt(editorSongs.pagination.totalCount)}
                        // pageRangeDisplayed={pageRangeDisplayed}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                /> : ''
            }
            </div>
        </div>
    </MusicDashboard>
    )
}

function mapStateToProps(state) {
  return {
    editorSongs: state.editor.editorSongs
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getEditorSongs: (data) => dispatch(actions.getEditorSongs(data)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(EditorSongs);