import React, { useState, useEffect } from "react";

import { checkCustomer } from '../../components/utlity/checkCustomer';
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import GenresInfoButton from '../../elements/songElements/genresInfoButton';
import FavouriteButton from '../../elements/songElements/favouriteButton';
import DownloadAllButton from '../../elements/songElements/downloadAllButton';
import DropboxAllButton from '../../elements/songElements/dropboxAllBtn';
import SongToggleButton from '../../elements/songElements/songToggleButton';
import PlayPauseButton from '../../elements/songElements/playPauseButton';
import SongVersion from './songVersion';
import PlaylistPopup from './playlistPopup';

const  SongTrack = ({ songs, sectionName, activeBtn, isCuratedSpotlight, customerProfile, displayDates }) => {

    const [toggleSong, setToggleSong ] = useState(false);
    const [activeSongId, setActiveSongId] = useState(0);
    const [customClassName, setCustomClassName] = useState("block");
    const [oneClickDownload, setOneClickDownload ] = useState(false);
    const [ canAccess, setCanAccess ] = useState(false);

    useEffect(() => { 
        if(checkCustomer(customerProfile)){
            setCanAccess(true);
        }
    },[customerProfile])

    const setSongToggle = (songId) => {
        
        if(activeSongId == songId ){
            setToggleSong(!toggleSong)
        } else {
            setToggleSong(true)
        }
        setActiveSongId(songId);
        
    }
    
    useEffect(() => { 
        setOneClickDownload(parseInt(customerProfile.one_click_download));
    },[customerProfile]);

    return (
        <div className="play-list">
        {
            songs && songs.length > 0 ? 
                songs.map((song, index)=>{
                    return <div className={(toggleSong && activeSongId == song.entity_id) || oneClickDownload ? "block active yellow-border" : "block yellow-border"} key={`song${sectionName}${song.entity_id}${index}`}>
                                <div className="play-track" onClick={() => setSongToggle(song.entity_id)}>
                                    <PlayPauseButton parentComponent="songTrack" song={song} />
                                    <div className="track-name">
                                        <span className="text-2" dangerouslySetInnerHTML={{__html: song.title}}></span>
                                        
                                        <div className="artist-name">
                                            { 
                                                song.artist_search && song.artist_search && song.artist_search.length > 0 ? 
                                                    song.artist_search.map((as, asIndex) => {
                                                        // return <div key={`as${asIndex}-${song.entity_id}`} ><Link to={`/mp3/artist/${as}`} dangerouslySetInnerHTML={{__html: as.trim()}} className="text-5"></Link> <span className="text-5">&nbsp;{ asIndex < (song.artist_search[0].artist.length -1) ? song.artist_search[0].split_string : ''}&nbsp;</span></div>
                                                        return <Link key={`as${asIndex}-${song.entity_id}`} to={`/mp3/artist/${as}`} className="text-5"><span dangerouslySetInnerHTML={{__html: as.trim()}}></span>{ asIndex != (song.artist_search.length -1) ? ', ' : ''}</Link>
                                                    })
                                                :
                                                <div><Link to={`/mp3/artist/${song.artist}`} dangerouslySetInnerHTML={{__html: song.artist}} className="text-5"></Link></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="play-track-content">
                                    <div>
                                        <span className="text-4">{song.max_bpm}</span>
                                        <span className="text-4">{song.song_key}</span>
                                        { displayDates ? <span className="text-5">{song.created_at}</span> : '' }
                                    </div>
                                    {/* <div className="divider">
                                        <InfoButton song={song} />
                                        <GenresInfoButton song={song} />
                                    </div> */}
                                    <div className="divider">
                                        { song.download_count && song.download_count ? <span className="st-fav m-0 download-report-count">{song.download_count} </span> : canAccess ? <span className="st-fav m-0"><FavouriteButton song={song} /></span> : '' }
                                        { activeBtn == 'download' ? <span className="st-download m-0"><DownloadAllButton entity_id={song.entity_id} isCkExclusive={song.isCkExclusive} isCuratedSpotlight={isCuratedSpotlight} totalDownload={song.download_count} /></span> : ''}
                                        { activeBtn == 'dropbox' ? <span className="st-dropbox m-0"><DropboxAllButton entity_id={song.entity_id} isCkExclusive={song.isCkExclusive} isCuratedSpotlight={isCuratedSpotlight} /></span> : ''}
                                        { canAccess  ? <PlaylistPopup song={song} /> : '' }
                                    </div>
                                    { 
                                        oneClickDownload ? 
                                        ''
                                        : 
                                        <div>
                                            <SongToggleButton songId={song.entity_id} setSongToggle={setSongToggle} toggleSong={toggleSong}  />
                                        </div>
                                    }
                                </div>
                                <div className="version-container">
                                {
                                    song.version && song.version.length > 0 ?
                                        song.version.map((version, index) => {
                                            return <div className='block-inner-content' key={`song${sectionName}version${song.entity_id}${version.song_version_id}`}>
                                                <SongVersion version={version} songId={song.entity_id} songSectionId={song.song_section_id} song={song} isCuratedSpotlight={isCuratedSpotlight} />
                                            </div>
                                        })
                                    : ''
                                }
                                </div>
                        </div>
                    })
            : <div className="text-2 alert alert-danger">No result found</div>
        }
        
        </div>
    );
  
}


function mapStateToProps(state) {
  return {
    activeBtn: state.activeDownloadBtn.activeDownloadBtn,
    customerProfile: state.customer.customerProfile
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(SongTrack);
