import React, {useEffect}  from "react";

import AccountNav from '../../elements/accountElements/accountNav'; 
import MusicDashboard from "../../layouts/musicDashboard";
import SuccessSvg from "../../assets/images/success-icon.svg";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';




const SuccessPayment = ({}) => {
    const gotoHome = () =>{
        window.location.href = '/';
    }
    const gotoSubscription = () => {
        window.location.href = '/subscription/plans';
    }
    return (
        <MusicDashboard accountPages={true} middleSectionCssClass={"account-info-sec full-section"}>
            <div className="content-sec">
                <div className="account-block">
                    <div className="top-heading">
                        <h2>Account Setting</h2>
                    </div>
                    <AccountNav/> 
                    <div className="account-inner-block">
                        <div className="subscription-sec">
                            <div className="payment-block msg-popup success" >
								<div className="right">
									<div className="box">
										<div className="body-text">
											<img src={SuccessSvg} />
											<h1>Success!</h1>
											<span className="text-3">Payment is currently processing.
                                            It may take some time to complete. Please click the button below to proceed. If you encounter any issues while downloading music, feel free to contact us at <a className="text-3 like-subscription" href="mailto:info@clubkillers.com">info@clubkillers.com</a>.</span>
										</div>
										
									</div>
									
									{/* <Link to={`/`} className="secondary-btn">Back to main page</Link> */}
                                    <span className="cp secondary-btn" onClick={()=>gotoHome()}>Back to main page</span>
                                    <span className="cp text-3 like-subscription" onClick={()=>gotoSubscription()}>Go to your subscriptions</span>
									{/* <Link className="text-3 like-subscription" to={`/subscription/plans`}>Go to your subscriptions</Link> */}
								</div>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </MusicDashboard>
    )
}
function mapStateToProps(state) {
    return {
        customer: state.customer,
    };
}

function mapDispatchToProps(dispatch) {
  	return {
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPayment);