import React, { useState, useEffect } from "react";
import { Link }  from "react-router-dom"
import SongTrack from '../../components/songTrack/songTrack';
import {connect} from 'react-redux';
import * as songActions from '../../store/song/actions';
import Spinner from 'react-bootstrap/Spinner';
import SongTrackLoader from '../loader/songTrackLoader';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

const  HomeGenresSong = ({  homeGenreData, homeGenresList, getHomePageGenreSongs, displayLoading, hideLoading }) => {

	const [activeCategoryGenre, setActiveCategoryGenre] = useState('31');
	const [activeNewReleaseGenre, setActiveNewReleaseGenre] = useState('all');

	
	useEffect(() => { 
		loadData();
	},[])

	const loadData =  () => {
		displayLoading();
		const data = {
			pageName: 'home'
		};

		// Get Home Page Genres Song
		getHomePageGenreSongs();
		hideLoading();
	}
	   
	const getCategoryGenreSong = (genreId) => {
		displayLoading();
		getHomePageGenreSongs().then((res) => {
			setActiveCategoryGenre(genreId);
		});
		hideLoading();
	}

	if(homeGenreData && homeGenreData.length > 0){
		return (
				homeGenreData.map((genre, index)=>{
					return  <div className="play-list-sec" key={`home-song-genre-${genre.genre_id}`}>
								{ genre.songs && genre.songs.length > 0 ?  <div className="top-heading"><h2><Link to={`/mp3/genres/${genre.genre_id}`}>{genre.genre_name}</Link></h2></div> : ''}
					{
						genre.songs && genre.songs.length > 0 ? 
							<div className="inner-block">
								<SongTrack  songs={genre.songs} sectionName={'homeGenresSong'}  displayDates={true} />
								
							</div> : ''
					}
					{ genre.songs && genre.songs.length > 0 ?  <Link className="primary-btn view-all-genres-btn" to={`/mp3/genres/${genre.genre_id}`}>View All</Link> : '' }
					</div>
				})
		)
		
	 } else {
		 return <SongTrackLoader itemCount={5} />
	 }
  
}


function mapStateToProps(state) {
	return {
		homeGenresList: state.genre.homeGenreList,
		homeNewRelease: state.song.homeNewRelease,
		homeGenreData: state.song.homeGenreData
	};
 }
 
 function mapDispatchToProps(dispatch) {
	return {
		getHomePageGenreSongs: () => dispatch(songActions.getHomePageGenreSongs()),
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(HomeGenresSong);
